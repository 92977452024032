<template>
  <div>
    <query-form class="content_block" label-width="100px" @search="handleSearch" @reset="handleReset">
      <el-form-item label="个人仓库：" prop="warehouseId">
        <el-select v-model="form.warehouseId" prop="warehouseId">
          <el-option :value="warehouseId" label="全部"></el-option>
          <el-option
            v-for="item in warehouseList"
            :key="item.warehouseId"
            :value="item.warehouseId"
            :label="item.warehouseName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备件名称：" prop="sparePartName">
        <el-input v-model="form.sparePartName" placeholder="请输入备件名称"></el-input>
      </el-form-item>
      <el-form-item label="备件编码：" prop="sparePartCode">
        <el-input v-model="form.sparePartCode" placeholder="请输入备件编码"></el-input>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
    <el-dialog title="备件退回" :visible.sync="dealDialog" width="60%">
      <el-form :model="dealForm" label-width="120px" :rules="backFormRules" ref="dealFormRef">
        <el-row>
          <el-col :span="6">
            <el-form-item label="申请人：" prop="createUserName">
              <div>{{ dealForm.createUserName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备件名称：" prop="sparePartName">
              <div>{{ dealForm.sparePartName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备件编码：" prop="sparePartCode">
              <div>{{ dealForm.sparePartCode }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备件类型：" prop="sparePartTypeName">
              <div>{{ dealForm.sparePartTypeName }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="备件规格：" prop="spec">
              <div>{{ dealForm.spec }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="退回仓库：" prop="goalWarehouseId">
              <el-select v-model="dealForm.goalWarehouseId">
                <el-option
                  v-for="item in warehouseSearchList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="个人库存数量：" prop="quantityTotal">
              <div>{{ dealForm.quantityTotal }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="退回数量：" prop="quantity">
              <el-input-number :min="0" :precision="0" v-model="dealForm.quantity"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="备注：" prop="remark">
              <el-input :maxlength="300" v-model="dealForm.remark" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDealHandle">取 消</el-button>
        <el-button type="primary" @click="sureDealHandle">退 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import _ from 'lodash';
import { getPersonalList, backPersonalList, getWarehouseList, getPersonalWarehouseList } from '@/api/stock.js';

export default {
  name: 'STOCKIN_PERSONAL',
  mixins: [TablePaginationMixin],
  data () {
    return {
      dealDialog: false,
      dealForm: {
        goalWarehouseId: undefined,
      },
      backFormRules: {
        quantity: [{ required: true, message: '请输入大于0的整数', trigger: 'blur' }],
        goalWarehouseId: [{ required: true, message: '请选择退回仓库', trigger: 'change' }],
      },
      form: {
        sparePartName: '',
        sparePartCode: '',
        warehouseId: undefined,
      },
      warehouseList: [],
      customerList: [],
      columnList: [
        {
          tooltip: true,
          label: '个人仓库',
          minWidth: '180px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: '备件名称',
          minWidth: '180px',
          prop: 'sparePartName',
        },
        {
          tooltip: true,
          label: '备件编码',
          minWidth: '180px',
          prop: 'sparePartCode',
        },
        {
          tooltip: true,
          label: '备件类型',
          minWidth: '180px',
          prop: 'sparePartTypeName',
        },
        {
          tooltip: true,
          label: '备件规格型号',
          minWidth: '180px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '库存数量',
          minWidth: '180px',
          prop: 'quantity',
        },
        {
          tooltip: true,
          label: '占用数量',
          minWidth: '180px',
          prop: 'occupyQuantity',
        },
        {
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            if (row.quantity > 0) {
              actions.push(
                <span class='cursor-pointer text-primary ml-1 ' onClick={this.openBackDialog.bind(this, row)}>
                    退回
                </span>,
              );
            }

            return actions;
          },
        },
      ],
      curRow: {},
      warehouseSearchList: [],
    };
  },
  async created () {
    const rs = await getPersonalWarehouseList();
    this.warehouseList = rs?.body || [];
  },
  methods: {
    cancelDealHandle () {
      this.dealDialog = false;
      this.dealForm.goalWarehouseId = '';
      this.dealForm.remark = '';
    },
    sureDealHandle () {
      this.$refs.dealFormRef.validate(async (valid) => {
        if (valid) {
          if (+this.dealForm.quantity <= 0) return this.$message.error('退回数量不能小于1');
          const param = {
            remark: this.dealForm.remark,
            id: this.dealForm.id,
            quantity: this.dealForm.quantity,
            goalWarehouseId: this.dealForm.goalWarehouseId,
          };
          const rs = await backPersonalList(param);
          if (rs.heads.code === 200) {
            this.$message.success('备件退回申请成功');
            this.cancelDealHandle();
            this.handleSearch();
          }
        }
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.form.sparePartName = '';
      this.form.sparePartCode = '';
      this.form.warehouseId = undefined;
      this.handleSearch();
    },
    async getTableList () {
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      const rs = await getPersonalList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
    openBackDialog (row) {
      this.dealForm = _.cloneDeep(row);
      if (this.dealForm.quantity !== undefined) {
        this.dealForm.quantityTotal = this.dealForm.quantity;
        this.dealForm.quantity = '';
      }
      this.dealForm.remark = '';
      getWarehouseList().then((rs) => {
        this.warehouseSearchList = rs?.body?.list;
        this.dealDialog = true;
      });
    },
  },
};
</script>
